<template>
  <div>
    <b-badge
      v-if="status == TournamentStatusType.DRAFT"
      variant="light-primary"
      size="sm"
    >
      Draft
    </b-badge>

    <b-badge
      v-if="status == TournamentStatusType.PUBLISHED"
      variant="light-info"
      size="sm"
    >
      Signup phase
    </b-badge>

    <b-badge
      v-if="status == TournamentStatusType.IN_PROGRESS"
      variant="light-success"
      size="sm"
    >
      Currently running
    </b-badge>

    <b-badge
      v-if="status == TournamentStatusType.FINISHED"
      variant="light-warning"
      size="sm"
    >
      ALL MATCHES PLAYED PLEASE CLOSE TOURNAMENT
    </b-badge>

    <b-badge
      v-if="status == TournamentStatusType.CLOSED"
      variant="light-dark"
      size="sm"
    >
      FINISHED AND CLOSED
    </b-badge>
  </div>
</template>

<script>
import TournamentStatusType from '@/constants/TournamentStatusType'
import { BBadge } from 'bootstrap-vue'

export default {
  components: {
    BBadge,
  },
  props: {
    status: {
      type: Number,
      default: () => TournamentStatusType.DRAFT,
    },
  },
  data() {
    return {
      TournamentStatusType,
    }
  },
  computed: {},
}
</script>
