<template>
  <b-modal
    id="organizer-tournament-race-results-modal"
    no-close-on-backdrop
    size="lg"
    title="Save race results"
    @hidden="onHidden"
  >
    <template #modal-footer="{ cancel }">
      <b-row class="w-100 mr-1 ml-1 justify-content-start">
        <dismissible-message-box
          :auto-dismiss="6000"
          :show="showMessage"
          :variant="notificationMessageType"
          :dismiss="() => { showMessage = false }"
          class="p-1"
        >
          <feather-icon
            class="mr-50"
            icon="InfoIcon"
          />
          {{ message }}
        </dismissible-message-box>
      </b-row>
      <div class="row justify-content-end w-100 mr-1 ml-1">

        <b-button
          variant="outline-primary"
          @click="cancel"
        >
          Close
        </b-button>
      </div>
    </template>

    <b-container>

      <b-table
        ref="refRaceResultsTable"
        :busy.sync="isLoading"
        :fields="tableColumns"
        :items="raceResults || []"
        empty-text="No race results yet"
        primary-key="id"
        responsive
        show-empty
      >

        <template #cell(elapsed_time)="data">
          <b-form-group
            label="Time"
            label-for="elapsedTime"
          >
            <cleave
              id="elapsedTime"
              v-model="data.item.formattedTime"
              class="form-control"
              :raw="false"
              :options="customDelimiter"
              placeholder="HH:MM:SS.mmm"
              @blur="updateRaceResult(data.item)"
            />
          </b-form-group>
        </template>

        <template #cell(participant)="data">
          {{ data.item.participant.name }}
        </template>

        <template #cell(rank)="data">
          {{ data.index + 1 }}
        </template>

        <template #table-busy>
          <div class="text-center">
            <b-spinner class="align-middle mr-2" />
            <strong>Loading...</strong>
          </div>
        </template>
      </b-table>

    </b-container>
  </b-modal>
</template>

<script>
import {
  BButton,
  BContainer, BFormGroup,
  BModal,
  BRow, BTable,
  VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import MessageType from '@/constants/MessageType'
import DismissibleMessageBox from '@/components/DismissibleMessageBox.vue'
import Cleave from 'vue-cleave-component'
import { millisecondsToTime, timeToMilliseconds } from '@/common/date-utils'

export default {

  components: {
    BTable,
    DismissibleMessageBox,
    BModal,
    BRow,
    BButton,
    BContainer,
    Cleave,
    BFormGroup,
  },

  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    tournament: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      MessageType,
      tournamentLocal: this.tournament,
      customDelimiter: {
        delimiters: [':', ':', '.'],
        blocks: [2, 2, 2, 3],
        uppercase: true,
      },
      tableColumns: [
        { key: 'elapsed_time', label: 'Time', sortable: false },
        { key: 'participant', label: 'Participant', sortable: false },
        { key: 'rank', label: 'Rank', sortable: false },
      ],
      isLoading: false,
      notificationMessageType: null,
      message: 'Results saved successfully',
      showMessage: false,
    }
  },
  computed: {
    raceResults() {
      let results = this.tournamentLocal?.matches[0]?.results?.slice(0)

      results = results.sort((a, b) => {
        if (a.elapsed_time === null || a.elapsed_time === 0) {
          return 1
        }

        if (b.elapsed_time === null || b.elapsed_time === 0) {
          return -1
        }

        return a.elapsed_time - b.elapsed_time
      })

      return results.map(result => ({
        ...result,
        formattedTime: millisecondsToTime(result.elapsed_time),
      }))
    },
  },
  watch: {
    tournament(newTournament) {
      this.tournamentLocal = newTournament
    },
  },
  methods: {
    async updateRaceResult(item) {
      this.message = null
      this.showMessage = false

      // check if previous elapsed_time equal to current elapsed_time
      if (item.elapsed_time === timeToMilliseconds(item.formattedTime)) {
        return
      }

      const milliseconds = timeToMilliseconds(item.formattedTime)

      const { success } = await this.$api.tournament.saveRaceMatchResult({
        id: this.tournamentLocal.matches[0].results.find(result => result.id === item.id).id,
        elapsed_time: milliseconds || 0,
        match_id: this.tournamentLocal.matches[0].id,
      })

      if (!success) {
        this.message = 'Failed saving race result'
        this.showMessage = true
        this.notificationMessageType = MessageType.ERROR

        return
      }

      const updatedItem = this.tournamentLocal?.matches[0]?.results.find(result => result.id === item.id)
      if (updatedItem) {
        updatedItem.elapsed_time = milliseconds
      }

      this.message = 'Results saved successfully'
      this.showMessage = true
      this.notificationMessageType = MessageType.SUCCESS

      setTimeout(() => {
        this.$emit('onRaceResultUpdated')
      }, 4000)
    },
    onHidden() {
      this.$emit('onRaceResultUpdated')
    },

  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-sweetalert.scss';
@import '~@core/scss/vue/libs/vue-select.scss';

.modal-xl {
  margin-left: 17%;
  margin-right: 17%;
}

.modal .modal-header .close {
  transform: translate(-4px, 5px);

  &:hover,
  &:focus,
  &:active {
    transform: translate(-7px, 6px);
  }
}
</style>
