var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "organizer-tournament-race-results-modal",
      "no-close-on-backdrop": "",
      "size": "lg",
      "title": "Save race results"
    },
    on: {
      "hidden": _vm.onHidden
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref) {
        var cancel = _ref.cancel;
        return [_c('b-row', {
          staticClass: "w-100 mr-1 ml-1 justify-content-start"
        }, [_c('dismissible-message-box', {
          staticClass: "p-1",
          attrs: {
            "auto-dismiss": 6000,
            "show": _vm.showMessage,
            "variant": _vm.notificationMessageType,
            "dismiss": function dismiss() {
              _vm.showMessage = false;
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "InfoIcon"
          }
        }), _vm._v(" " + _vm._s(_vm.message) + " ")], 1)], 1), _c('div', {
          staticClass: "row justify-content-end w-100 mr-1 ml-1"
        }, [_c('b-button', {
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": cancel
          }
        }, [_vm._v(" Close ")])], 1)];
      }
    }])
  }, [_c('b-container', [_c('b-table', {
    ref: "refRaceResultsTable",
    attrs: {
      "busy": _vm.isLoading,
      "fields": _vm.tableColumns,
      "items": _vm.raceResults || [],
      "empty-text": "No race results yet",
      "primary-key": "id",
      "responsive": "",
      "show-empty": ""
    },
    on: {
      "update:busy": function updateBusy($event) {
        _vm.isLoading = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "cell(elapsed_time)",
      fn: function fn(data) {
        return [_c('b-form-group', {
          attrs: {
            "label": "Time",
            "label-for": "elapsedTime"
          }
        }, [_c('cleave', {
          staticClass: "form-control",
          attrs: {
            "id": "elapsedTime",
            "raw": false,
            "options": _vm.customDelimiter,
            "placeholder": "HH:MM:SS.mmm"
          },
          on: {
            "blur": function blur($event) {
              return _vm.updateRaceResult(data.item);
            }
          },
          model: {
            value: data.item.formattedTime,
            callback: function callback($$v) {
              _vm.$set(data.item, "formattedTime", $$v);
            },
            expression: "data.item.formattedTime"
          }
        })], 1)];
      }
    }, {
      key: "cell(participant)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.item.participant.name) + " ")];
      }
    }, {
      key: "cell(rank)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.index + 1) + " ")];
      }
    }, {
      key: "table-busy",
      fn: function fn() {
        return [_c('div', {
          staticClass: "text-center"
        }, [_c('b-spinner', {
          staticClass: "align-middle mr-2"
        }), _c('strong', [_vm._v("Loading...")])], 1)];
      },
      proxy: true
    }])
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }