<template>
  <b-modal
    id="organizer-tournament-participant-modal"
    no-close-on-backdrop
    size="lg"
    title="Add participants"
    @hidden="onHidden"
  >
    <template #modal-footer="{ cancel }">
      <b-row class="w-100 mr-1 ml-1 justify-content-start">
        <dismissible-message-box
          :auto-dismiss="6000"
          :show="showMessage"
          :variant="notificationMessageType"
        >
          <feather-icon
            class="mr-50"
            icon="InfoIcon"
          />
          {{ message }}
        </dismissible-message-box>
      </b-row>
      <div class="row justify-content-end w-100 mr-1 ml-1">
        <b-button
          variant="outline-primary"
          @click="cancel"
        >
          Close
        </b-button>
      </div>
    </template>

    <b-container>
      <p>Add participants to the tournament. Use email to invite participants to the platform so they can edit and
        modify their profile. Email is not required. You can later remove participants and re-add if you made a
        mistake.</p>
      <h5>Add single participant</h5>
      <validation-observer ref="singleParticipantObserver">
        <b-row>

          <b-col
            md="12"
            lg="6"
          >
            <b-form-group>
              <label
                for="name"
              >
                {{ participantType === TournamentParticipantType.player ? 'Player name ' : 'Team name' }}
              </label>
              <validation-provider
                v-slot="{ errors: componentErrors }"
                name="name"
                rules="required"
              >

                <b-form-input
                  v-model="participant.name"
                  :placeholder="
                    participantType === TournamentParticipantType.team
                      ? 'Team name'
                      : 'Player name'
                  "
                  name="name"
                  @change="participant.participant_id = null"
                />
                <small class="text-danger">{{ componentErrors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            md="12"
            lg="6"
          >
            <b-form-group>
              <label for="email">Email:</label>
              <validation-provider
                v-slot="{ errors: componentErrors }"
                name="email"
                rules="email"
              >
                <b-form-input
                  v-model="participant.email"
                  name="email"
                  placeholder="john@example.com"
                />
                <small class="text-danger">{{ componentErrors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>

      <b-row>
        <b-col>
          <spinning-button
            :is-loading="isLoadingSingle"
            type="submit"
            variant="primary"
            @click.native="handleSubmitSingle"
          >
            Add participant
          </spinning-button>
        </b-col>
      </b-row>

      <b-row v-if="participantType === TournamentParticipantType.team">
        <b-col md="12">
          <b-form-group
            v-for="(l, index) in lineup"
            :key="index"
          >
            <label for="email">Player {{ index + 1 }}:</label>
            <b-row>
              <b-col>
                <v-select
                  id="player"
                  ref="selectPlayer"
                  v-model="l.participant_id"
                  :options="playersOptions"
                  :reduce="player => player.code"
                />
              </b-col>
              <b-col>
                <b-form-input
                  v-model="l.name"
                  name="player-name"
                  placeholder="Player name"
                />
              </b-col>
            </b-row>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col>
          <h5>Bulk add participants</h5>
        </b-col>
      </b-row>
      <validation-observer ref="bulkParticipantObserver">
        <b-row>
          <b-col>
            <b-form-group>
              <label for="bulkParticipants">Participants:</label>
              <validation-provider
                v-slot="{ errors: componentErrors }"
                name="participants"
                rules="required"
              >
                <p>Participants name is mandatory and you can also add participants email by separating name and email by
                  coma.</p>
                <p>Example: TEAM A,winners@gmail.com</p>

                <textarea
                  v-model="bulkParticipants"
                  class="form-control"
                  rows="5"
                  placeholder="Add one participant per line"
                />
                <small class="text-danger">{{ componentErrors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>

      <b-row class="mt-1">
        <b-col>
          <spinning-button
            :is-loading="isLoadingBulk"
            type="submit"
            variant="primary"
            @click.native="handleSubmitMultiple"
          >
            Add participants in bulk
          </spinning-button>
        </b-col>
      </b-row>

    </b-container>
  </b-modal>
</template>

<script>
import {
  BButton,
  BCol,
  BContainer,
  BFormGroup,
  BFormInput,
  BModal,
  BRow,
  VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import {
  required,
} from '@core/utils/validations/validations'
import MessageType from '@/constants/MessageType'
import TournamentParticipantType from '@/constants/TournamentParticipantType'
import SpinningButton from '@/components/SpinningButton.vue'
import DismissibleMessageBox from '@/components/DismissibleMessageBox.vue'

export default {

  components: {
    DismissibleMessageBox,
    BModal,
    BFormGroup,
    BFormInput,
    ValidationObserver,
    ValidationProvider,
    BRow,
    BCol,
    BButton,
    BContainer,
    SpinningButton,
    vSelect,
  },

  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    participantType: {
      type: Number,
      default: TournamentParticipantType.team,
    },
    playersPerTeam: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      TournamentParticipantType,
      required,
      MessageType,
      notificationMessageType: null,
      message: 'Participant saved successfully',
      showMessage: false,
      email: '',
      name: null,
      errors: [],
      showErrorNotifications: false,
      showSuccessNotifications: false,
      isLoadingSingle: false,
      isLoadingBulk: false,
      participantOptions: [],
      playersOptions: [],
      participant: {
        name: null,
        email: null,
      },
      bulkParticipants: [],
    }
  },
  computed: {
    lineup() {
      const items = this.participant?.lineup || []
      if (items.length < this.playersPerTeam) {
        // eslint-disable-next-line no-plusplus
        for (let i = this.playersPerTeam - items.length; i > 0; i--) {
          items.push({
            id: null,
            name: null,
            participant_id: null,
          })
        }
      }
      return items
    },
  },
  methods: {
    onHidden() {
      this.participant = {
        name: null,
        email: null,
      }
      this.message = null
      this.showMessage = false

      this.$emit('onParticipantsAdded')
    },
    async handleSubmitMultiple() {
      this.message = null
      this.showMessage = false

      const formValid = await this.$refs.bulkParticipantObserver.validate()
      if (!formValid) {
        return
      }

      const participants = this.bulkParticipants.split('\n')

      const payload = participants.map(participant => {
        const [name, email = null] = participant.split(',')
        return {
          tournament_id: Number(this.$route.params.tournamentId),
          participant_type: this.participantType,
          name,
          email,
        }
      })

      this.isLoadingBulk = true

      const { errors } = await this.$api.tournament.saveParticipants(
        payload,
      )
      this.isLoadingBulk = false

      if (errors) {
        this.message = 'Failed to save participant'
        this.showMessage = true
        this.notificationMessageType = MessageType.ERROR
        return
      }

      this.showMessage = true
      this.message = 'Participant saved successfully'
      this.notificationMessageType = MessageType.SUCCESS

      this.bulkParticipants = null
    },
    async handleSubmitSingle() {
      this.message = null
      this.showMessage = false

      const formValid = await this.$refs.singleParticipantObserver.validate()
      if (!formValid) {
        return
      }

      const payload = {
        tournament_id: Number(this.$route.params.tournamentId),
        participant_type: this.participantType,
        name: this.participant.name,
        email: this.participant.email,
      }

      this.isLoadingSingle = true

      const { errors } = await this.$api.tournament.saveParticipants(
        [payload],
      )
      this.isLoadingSingle = false

      if (errors) {
        this.message = 'Failed to save participant'
        this.showMessage = true
        this.notificationMessageType = MessageType.ERROR
        return
      }

      this.showMessage = true
      this.message = 'Participant saved successfully'
      this.notificationMessageType = MessageType.SUCCESS

      this.participant = {
        name: null,
        email: null,
      }
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-sweetalert.scss';
@import '~@core/scss/vue/libs/vue-select.scss';

.modal-xl {
  margin-left: 17%;
  margin-right: 17%;
}

.modal .modal-header .close {
  transform: translate(-4px, 5px);

  &:hover,
  &:focus,
  &:active {
    transform: translate(-7px, 6px);
  }
}
</style>
