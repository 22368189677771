var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.status == _vm.TournamentStatusType.DRAFT ? _c('b-badge', {
    attrs: {
      "variant": "light-primary",
      "size": "sm"
    }
  }, [_vm._v(" Draft ")]) : _vm._e(), _vm.status == _vm.TournamentStatusType.PUBLISHED ? _c('b-badge', {
    attrs: {
      "variant": "light-info",
      "size": "sm"
    }
  }, [_vm._v(" Signup phase ")]) : _vm._e(), _vm.status == _vm.TournamentStatusType.IN_PROGRESS ? _c('b-badge', {
    attrs: {
      "variant": "light-success",
      "size": "sm"
    }
  }, [_vm._v(" Currently running ")]) : _vm._e(), _vm.status == _vm.TournamentStatusType.FINISHED ? _c('b-badge', {
    attrs: {
      "variant": "light-warning",
      "size": "sm"
    }
  }, [_vm._v(" ALL MATCHES PLAYED PLEASE CLOSE TOURNAMENT ")]) : _vm._e(), _vm.status == _vm.TournamentStatusType.CLOSED ? _c('b-badge', {
    attrs: {
      "variant": "light-dark",
      "size": "sm"
    }
  }, [_vm._v(" FINISHED AND CLOSED ")]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }