<template>
  <div>
    <b-row v-if="loading">
      <b-col>
        <b-spinner
          label="Loading..."
        />
      </b-col>
    </b-row>

    <b-row v-else>
      <!-- 1st Col -->
      <b-col
        lg="5"
        md="12"
      >
        <!-- tournament info -->
        <b-card>
          <b-row>
            <b-col
              class="d-flex"
              lg="12"
              md="12"
            >
              <b-avatar
                :src="tournament?.discipline?.image"
                :variant="`light-primary`"
                size="60px"
                square
              />
              <div class="mr-auto ml-1">
                <h6>{{ tournament?.name }}</h6>
                <organizer-tournament-status-badge :status="tournament?.status_id" />
              </div>

              <div class="d-flex flex-column">
                <b-button
                  v-if="
                  tournament?.status_id !== TournamentStatusType.IN_PROGRESS &&
                  tournament?.status_id !== TournamentStatusType.FINISHED &&
                  tournament?.status_id !== TournamentStatusType.CLOSED
                  "
                  class="mb-1"
                  variant="primary"
                  @click="redirectToEdit"
                >
                  Edit
                </b-button>

                <b-button
                  variant="primary"
                  class="mb-1"
                  @click="openTournamentPage"
                >
                  Open
                </b-button>

              </div>
            </b-col>
          </b-row>

          <b-row class="mt-2">
            <b-col cols="6">
              <div>
                <span>Game:</span>
                <strong>{{ tournament?.discipline?.title }}</strong>
              </div>
              <div>
                {{
                  tournament?.participants ? tournament.participants.length : 0
                }}
                of
                {{ tournament?.participants_number }}
                {{
                  tournament?.participant_type_id ===
                  TournamentParticipantType.TEAM
                      ? 'teams'
                      : 'players'
                }}
                joined
              </div>
              <div>
                <span>Start:</span>
                <strong>{{ tournament?.start_at }}</strong>
              </div>
              <div>
                <strong>
                  {{ stageTypeTitle }}
                </strong>
              </div>
              <div>
                <span>Platforms:</span>
                <b-badge
                  v-for="(platform, index) in tournament?.platforms"
                  :key="index"
                  class="ml-1 mr-1"
                  size="sm"
                  variant="light-primary"
                >
                  {{ platform.title }}
                </b-badge>
              </div>
              <div>
                <span>Check-in:</span>
                <strong>
                  {{ tournament?.enable_checkin ? 'Yes' : 'No' }}
                </strong>
              </div>
            </b-col>

            <b-col cols="6">

              <!--              <b-row-->
              <!--                v-if="tournament?.status_id == TournamentStatusType.PUBLISHED"-->
              <!--                class="justify-content-end mb-1"-->
              <!--              >-->
              <!--                Check-in time expired please start tournament-->
              <!--              </b-row>-->

              <b-row
                v-if="tournament?.settings?.tournament_format !== TournamentFormat.RACE"
                class="mb-1 justify-content-end"
              >
                <b-button

                  variant="primary"
                  @click="redirectToBracket"
                >
                  Open bracket
                </b-button>
              </b-row>

              <b-row class="mb-1 justify-content-end">
                <b-button
                  v-if="tournament?.status_id === TournamentStatusType.DRAFT"
                  variant="info"
                  @click="publishTournament"
                >
                  Publish tournament
                </b-button>
                <b-button
                  v-if="tournament?.status_id === TournamentStatusType.PUBLISHED"
                  variant="primary"
                  @click="startTournament"
                >
                  Start tournament
                </b-button>

                <b-button
                  v-if="tournament?.status_id === TournamentStatusType.FINISHED || (tournament?.settings?.tournament_format === TournamentFormat.RACE && tournament?.status_id === TournamentStatusType.IN_PROGRESS)"
                  variant="primary"
                  @click="closeTournament"
                >
                  Close tournament
                </b-button>
              </b-row>

              <b-row
                v-if="tournament?.status_id === TournamentStatusType.FINISHED"
                class="justify-content-end"
              >
                All matches finished please double check if all is OK and use
                this button to transfer funds to wining teams/players..
              </b-row>
            </b-col>
          </b-row>
        </b-card>

        <!-- log -->
        <b-card title="Activity">
          <b-tabs
            align="left"
            pills
          >
            <b-tab
              active
              title="Logs"
            >
              No activity detected.
            </b-tab>

            <b-tab>
              <template #title>
                <span>Disputes</span>
                <feather-icon
                  v-if="disputes?.length > 0"
                  icon="AlertTriangleIcon"
                  class="ml-1"
                />
              </template>

              <b-row v-if="!disputes || disputes?.length === 0">
                <b-col sm="12">
                  No open disputes.
                </b-col>
              </b-row>

              <b-row
                v-for="dispute in disputes"
                v-else
                :key="dispute?.id"
              >
                <b-col sm="12">
                  <b-badge
                    size="sm"
                    class="p-1"
                    variant="light-danger"
                    :to="{
                      name: 'dashboard-tournament-matches',
                      params: { id: $route.params?.id, tournamentId: tournament?.id }
                    }"
                  >
                    <strong>
                      {{
                        dispute?.opponent1?.participant
                            ? dispute?.opponent1?.participant?.name
                            : dispute?.opponent1?.name
                      }}
                    </strong>
                    vs
                    <strong>
                      {{
                        dispute?.opponent2?.participant
                            ? dispute?.opponent2?.participant?.name
                            : dispute?.opponent2?.name
                      }}
                    </strong>
                  </b-badge>
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>
        </b-card>

        <!-- reports -->
        <b-card>
          <label class="mb-2">
            These players were reported by other players. Try to investigate what
            happened and if you think that player deserves investigation by
            platform admins please use report button. Thank you for helping
            building better community.
          </label>
          <div>No reports.</div>
        </b-card>
      </b-col>

      <!-- 2nd Col -->
      <b-col
        lg="4"
        md="12"
      >
        <!-- prize pool info -->
        <b-row>
          <b-col cols="12">
            <b-card
              class=""
              title="Current prize-pool"
            >
              <label>
                When tournament is finished your will have to confirm results and
                your fee and prize-pool will be automatically transferred to
                players/teams and tournament will close.
              </label>
            </b-card>
          </b-col>
        </b-row>

        <!-- open registrations -->
        <b-row v-if="tournament?.enable_registration === true">
          <b-col cols="12">
            <b-card
              class=""
              title="Open registrations"
            >
              <label>List of registered participants waiting confirmation</label>

              <b-row
                v-if="!registrations || registrations.length === 0"
                class="mt-1"
              >
                <b-col
                  class="d-flex align-items-center"
                  md="6"
                  sm="12"
                >
                  <p class="m-0">
                    <i>No pending registrations</i>
                  </p>
                </b-col>
              </b-row>

              <b-row
                v-for="registration in registrations"
                :key="registration.id"
              >
                <b-col
                  class="d-flex align-items-center"
                  md="8"
                  sm="12"
                >
                  <h6 class="m-0">
                    <b-link
                      v-if="registration.participant_type === TournamentParticipantType.TEAM"
                      :to="{name: 'team-profile', params: { id: registration.participant_id } }"
                    >
                      {{ registration.participant.name }}
                    </b-link>
                    <b-link
                      v-else
                      :to="{name: 'player-profile', params: { id: registration.participant_id } }"
                    >
                      {{ registration.participant.name }}
                    </b-link>
                  </h6>
                </b-col>
                <b-col
                  class="d-flex"
                  md="4"
                  sm="12"
                >
                  <b-button
                    class="mr-1"
                    size="sm"
                    variant="success"
                    @click="() => saveTournamentRegistration(registration, true)"
                  >
                    <b-spinner
                      v-if="isAccepting"
                      small
                    />
                    <feather-icon
                      v-else
                      icon="CheckIcon"
                      size="12"
                    />
                  </b-button>
                  <b-button
                    size="sm"
                    variant="danger"
                    @click="() => saveTournamentRegistration(registration, false)"
                  >
                    <b-spinner
                      v-if="isDeclining"
                      small
                    />
                    <feather-icon
                      v-else
                      icon="XIcon"
                      size="18"
                    />
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>

        <!-- race results -->
        <organizer-tournament-race-results-modal
          :tournament="tournament"
          @onRaceResultUpdated="fetchTournament"
        />
        <b-row v-if="tournament?.settings?.tournament_format === TournamentFormat.RACE">
          <b-col cols="12">
            <b-card
              class=""
              title="Race results"
            >
              <div>
                <b-table
                  ref="refRaceResultsTable"
                  :busy.sync="isLoading"
                  :fields="raceResultsTableCols"
                  :items="raceResults"
                  empty-text="No race results yet"
                  primary-key="id"
                  responsive
                  show-empty
                >
                  <template #cell(participant)="data">
                    {{
                      data.item.participant
                        ? data.item.participant.name
                        : data.item.name
                    }}
                  </template>

                  <template #cell(rank)="data">
                    {{ data.index + 1 }}
                  </template>

                  <template #cell(elapsed_time)="data">
                    {{ data.item.elapsed_time ? millisecondsToTime(data.item.elapsed_time) : 'no result yet' }}
                  </template>

                  <template #table-busy>
                    <div class="text-center">
                      <b-spinner class="align-middle mr-2" />
                      <strong>Loading...</strong>
                    </div>
                  </template>
                </b-table>
              </div>

              <div v-if="tournament?.status_id !== TournamentStatusType.CLOSED">
                <b-button
                  variant="primary"
                  size="sm"
                  @click="handleOpenSaveRaceResultsClick"
                >
                  Update race results
                </b-button>
              </div>
            </b-card>
          </b-col>
        </b-row>

        <!-- live matches -->
        <b-row v-else>
          <b-col cols="12">
            <b-card
              class=""
              title="Live matches"
            >
              <div class="mb-2">
                <label>No live matches.</label>
              </div>
              <div>
                <b-button
                  variant="primary"
                  size="sm"
                  @click="handleOpenMatchesClick"
                >
                  All matches
                </b-button>
              </div>
            </b-card>
          </b-col>
        </b-row>

        <!-- results -->
        <b-row
          v-if="
            (tournament?.status_id === TournamentStatusType.FINISHED ||
          tournament?.status_id === TournamentStatusType.CLOSED) && tournament?.settings?.tournament_format !== TournamentFormat.RACE
          "
        >
          <b-col cols="12">
            <b-card
              class=""
              title="Results"
            />
          </b-col>
        </b-row>
      </b-col>

      <!-- 3rd Col -->
      <b-col
        lg="3"
        md="12"
      >
        <!-- staff -->
        <b-card
          class=""
          title="Staff"
        >
          <b-badge
            v-for="member in tournament?.staff"
            :key="member.user.id"
            class="mr-1"
            variant="light-primary"
          >
            {{ member.user.name }}
          </b-badge>
        </b-card>

        <!-- participants -->
        <organizer-tournament-participant-modal
          :participant-type="tournament?.participant_type_id"
          :players-per-team="tournament?.players_per_team"
          @onParticipantsAdded="fetchTournament"
        />

        <b-card
          class=""
          title="Participants"
        >
          <label
            v-if="
              !tournament?.participants || tournament?.participants?.length == 0
            "
            class="font-italic"
          >
            No participants
          </label>

          <label v-if="tournament?.enable_registration === false">
            <p>Your tournament dose not have registrations open so please use button below to add participants!</p>
          </label>

          <div>
            <ul
              v-if="tournament?.participants && tournament?.participants.length > 0"
              class="list-unstyled d-flex flex-wrap"
            >
              <li
                v-for="participant in tournament.participants"
                :key="participant.id"
              >
                <b-badge
                  v-if="participant.participant_type === TournamentParticipantType.TEAM"
                  :to="
                    participant.participant
                      ? {
                        name: 'team-profile',
                        params: { id: participant.participant_id }
                      }
                      : null
                  "
                  class="m-1"
                  variant="light-primary"
                >
                  {{
                    participant.participant
                      ? participant.participant.name
                      : participant.name
                  }}
                </b-badge>
                <b-badge
                  v-else
                  :to="
                    participant.participant
                      ? {
                        name: 'player-profile',
                        params: { id: participant.participant_id }
                      }
                      : null
                  "
                  class="m-1"
                  variant="light-primary"
                >
                  {{
                    participant?.participant
                        ? participant.participant.name
                        : participant.name
                  }}
                </b-badge>
              </li>
            </ul>
          </div>
          <div
            v-if="tournament?.status_id < TournamentStatusType.IN_PROGRESS"
            class="d-flex align-items-start flex-column"
          >
            <b-button
              variant="primary"
              size="sm"
              class="my-1"
              @click="handleAddParticipantsClick"
            >
              ADD participants
            </b-button>

            <b-button
              variant="outline-primary"
              size="sm"
              @click="handleOpenParticipantsClick"
            >
              EDIT participants
            </b-button>
          </div>
        </b-card>
      </b-col>

      <!--      <match-result-modal-->
      <!--        :match="modalData"-->
      <!--        @close="modalData = null"-->
      <!--      />-->
      <!--      <b-button @click="() => (this.$bvModal.show('match-result-modal'))">-->
      <!--        Open modal-->
      <!--      </b-button>-->
    </b-row>
  </div>
</template>

<script>
import TournamentParticipantType from '@/constants/TournamentParticipantType'
import Ripple from 'vue-ripple-directive'
import TournamentStatusType from '@/constants/TournamentStatusType'
import {
  BAvatar, BBadge, BButton, BCard, BCol, BLink, BRow, BSpinner, BTab, BTable, BTabs,
} from 'bootstrap-vue'
import OrganizerTournamentStatusBadge from '@/views/dashboard/tournament/OrganizerTournamentStatusBadge.vue'
import MatchStatusType from '@/constants/MatchStatusType'
import OrganizerTournamentParticipantModal from '@/views/dashboard/OrganizerTournamentParticipantModal.vue'
import TournamentFormat from '@/constants/TournamentFormat'
import OrganizerTournamentRaceResultsModal from '@/views/dashboard/tournament/OrganizerTournamentRaceResultsModal.vue'
import { format } from 'date-fns'
import { millisecondsToTime } from '@/common/date-utils'

export default {
  components: {
    BTable,
    OrganizerTournamentRaceResultsModal,
    OrganizerTournamentParticipantModal,
    BTabs,
    BTab,
    OrganizerTournamentStatusBadge,
    BRow,
    BCol,
    BCard,
    BAvatar,
    BBadge,
    BButton,
    BSpinner,
    BLink,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      TournamentStatusType,
      TournamentParticipantType,
      MatchStatusType,
      tournament: null,
      registrations: null,
      modalData: null,
      isAccepting: false,
      isDeclining: false,
      loading: false,
      raceResultsTableCols: [
        {
          key: 'rank',
          label: 'Rank',
          sortable: false,
        },
        {
          key: 'elapsed_time',
          label: 'Time',
          sortable: false,
        },
        {
          key: 'participant',
          label: 'Participant',
          sortable: false,
        },
      ],
      isLoading: false,
    }
  },
  computed: {
    TournamentFormat() {
      return TournamentFormat
    },
    stageTypeTitle() {
      return this.tournament?.stages?.length > 0
        ? this.tournament?.stages[0].type.title
        : ''
    },
    disputes() {
      return this.tournament?.matches?.filter(
        match => match.status_id === MatchStatusType.DISPUTED,
      )
    },
    raceResults() {
      let results = this.tournament?.matches[0]?.results.slice(0)

      results = results.sort((a, b) => {
        if (a.elapsed_time === null || a.elapsed_time === 0) {
          return 1
        }

        if (b.elapsed_time === null || b.elapsed_time === 0) {
          return -1
        }

        return a.elapsed_time - b.elapsed_time
      })

      return results.map((result, index, array) => ({
        ...result,
        difference: result.elapsed_time && array[index - 1]?.elapsed_time ? result.elapsed_time - array[index - 1].elapsed_time : 0,
        gap: result.elapsed_time && array[index - 1]?.elapsed_time ? result.elapsed_time - array[0].elapsed_time : 0,
      })) || []
    },
  },
  async created() {
    await this.fetchTournament()

    this.modalData = {}
  },
  methods: {
    millisecondsToTime,
    format,
    handleAddParticipantsClick() {
      this.$bvModal.show('organizer-tournament-participant-modal')
    },
    handleOpenParticipantsClick() {
      this.$router.push({
        name: 'dashboard-tournament-participants',
        params: {
          id: this.$route.params.id,
          tournamentId: this.$route.params.tournamentId,
        },
      })
    },
    async fetchTournament() {
      this.loading = true
      // fetch tournament
      const { data } = await this.$api.tournament.fetchMyTournamentById(
        Number(this.$route.params.id),
        Number(this.$route.params.tournamentId),
      )
      this.loading = false
      this.tournament = data

      this.registrations = data.registrations.filter(
        registration => registration.is_confirmed === null,
      )
    },
    async publishTournament() {
      await this.$api.tournament.updateStatus(
        this.$route.params.tournamentId,
        TournamentStatusType.PUBLISHED,
      )

      this.tournament.status_id = TournamentStatusType.PUBLISHED
    },
    startTournament() {
      this.$swal({
        title: 'Starting tournament!',
        text: 'Bracket will be generated and teams wil be placed in bracket. This action can’t be undone and tournament will start.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, I am sure!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(async () => {
          const result = await this.$api.tournament.updateStatus(
            this.$route.params.tournamentId,
            TournamentStatusType.IN_PROGRESS,
          )

          if (result) {
            this.tournament.status_id = TournamentStatusType.IN_PROGRESS
          }
        })
    },
    redirectToBracket() {
      this.$router.push({
        name: 'dashboard-tournament-bracket',
        params: {
          id: this.$route.params.id,
          tournamentId: this.$route.params.tournamentId,
        },
      })
    },
    redirectToEdit() {
      this.$router.push({
        name: 'dashboard-tournaments-edit',
        params: {
          id: this.$route.params.id,
          tournamentId: this.$route.params.tournamentId,
        },
      })
    },
    closeTournament() {
      this.$swal({
        title: 'Tournament finished!',
        text: 'Please double check that all results are correct. When you confirm results tournament will be closed and prizes distributed.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Finish tournament',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(async () => {
          const result = await this.$api.tournament.updateStatus(
            this.$route.params.tournamentId,
            TournamentStatusType.CLOSED,
          )

          if (result) {
            this.tournament.status_id = TournamentStatusType.CLOSED
          }
        })
    },
    openTournamentPage() {
      this.$router.push({
        name: 'tournament-overview',
        params: {
          id: Number(this.$route.params.tournamentId),
          game: this.tournament?.discipline?.slug,
        },
      })
    },
    handleOpenMatchesClick() {
      this.$router.push({
        name: 'dashboard-tournament-matches',
        params: {
          id: this.$route.params.id,
          tournamentId: this.$route.params.tournamentId,
        },
      })
    },
    handleOpenSaveRaceResultsClick() {
      this.$bvModal.show('organizer-tournament-race-results-modal')
    },
    async saveTournamentRegistration(registration, confirmed) {
      if (confirmed) {
        this.isAccepting = true
      } else {
        this.isDeclining = true
      }

      await this.$api.tournament.saveRegistration({
        id: registration.id,
        is_confirmed: confirmed,
      })

      await this.fetchTournament()

      if (confirmed) {
        this.isAccepting = false
      } else {
        this.isDeclining = false
      }

      await this.$api.tournament.fetchMyTournamentById(Number(this.$route.params.id), Number(this.$route.params.tournamentId))

      this.registrations = this.registrations.filter(
        // eslint-disable-next-line no-shadow
        r => r.id !== registration.id,
      )
    },
  },
}
</script>
